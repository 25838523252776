import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck , ReCaptchaV3Provider} from "firebase/app-check";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: "jewelliq.firebaseapp.com",
  projectId: "jewelliq",
  storageBucket: "jewelliq.appspot.com",
  messagingSenderId: "451601098255",
  appId: "1:451601098255:web:2950b70269fc8015ba657a",
  measurementId: "G-02Z5M4TZD2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage = getStorage(app);

export default app

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcrmnIqAAAAABQgjIkUaUxPNYN0StK2WgvkZZG-'),
  isTokenAutoRefreshEnabled: true,
});
