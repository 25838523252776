import React, { forwardRef, useEffect, useRef, useState } from 'react'
import styles from './NewStockMng.module.scss'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useFetchCollection from '../../../customHook/useFetchCollection'
import ReactToPrint from 'react-to-print';
import dayjs from 'dayjs'
import { STORE_PRODUCTS, selectProducts } from '../../../redux/slice/productSlice'
import { useDispatch, useSelector } from 'react-redux';
import { doc, setDoc, writeBatch } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import useFetchCollectionNewStock from '../../../customHook/useFetchCollectionNewStock'

const NewStockMng = () => {
    const [hideDetail, setHideDetail] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data, isLoading} = useFetchCollection('products');
    const {newStock, isLoadingNewStock} = useFetchCollectionNewStock();
    const componentRef = useRef();
    const products = useSelector(selectProducts);

    useEffect(() => {
      dispatch(
              STORE_PRODUCTS({
                  products: data
              })
              )
    },[dispatch, data])

    const showDetail = id => {
      if (hideDetail) {
        setHideDetail(null)
      } else {
        setHideDetail(id)
      }
    }
    const handleConfirmStock = async (list) => {
      const itemArray = list.itemList;  
      const updates = itemArray.reduce((acc, item) => {
          const findProduct = products.find(product => product.id === item.id);
          if (findProduct && findProduct.stockControl) {
              const arr = [...findProduct.stockControl];
              const arrFindIndex = arr.findIndex(entry => entry.branch === list.branch); 
              const newState = { ...arr[arrFindIndex], qty: arr[arrFindIndex].qty + item.qty };
              arr.splice(arrFindIndex, 1, newState);
  
              acc.push({
                  ref: doc(db, 'products', item.id),
                  data: { stockControl: arr }
              });
          }
          return acc;
      }, []);
  
      // Use a batch write for efficiency
      const batch = writeBatch(db);
      updates.forEach(({ ref, data }) => batch.set(ref, data, { merge: true }));
      await batch.commit();
      await setDoc(doc(db, "newstock", list.id), 
              {
                  isConfirmStock: true,
              },
              {merge: true}
      )
    };

    const ComponentToPrint = forwardRef((props, ref) => {
      const {branch, date, itemList} = props.props;
      const startDate = dayjs(date, 'DD/MM/YYYY');
      const result = startDate.add(2, 'day').format('DD/MM/YYYY'); 
      return (
        <div ref={ref} className={styles.componenttoprint}>
          <div className={styles.headerprint}>
            <p>Jewelliq</p>
            <div className={styles.rightheader}>
              <p className={styles.title}>ใบส่งสินค้า</p>
              <p className={styles.branch}>สาขา {branch?.toUpperCase()}</p>
              <p>วันที่ส่งสินค้า {date}</p>
              <p>วันที่คืนสินค้า {result}</p>

            </div>
            
          </div>
          
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>รายการ</th>
                <th className={styles.qty}>จำนวน</th>
              </tr>
            </thead>
            <tbody>
            {itemList.map((list, index) => {
              const {name, qty, id} = list;
              return (
                <tr key={id}>
                  <td>
                    {index + 1}
                  </td>
                  <td>
                    {name?.toUpperCase()}
                  </td>
                  <td className={styles.qty}>
                    {qty}
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>


        </div>
      );
    });
  return (
    <div className={styles.newstockmanagement}>
        <h4 style={{paddingBottom: 10}}>New Stock Management</h4>
        <Button variant='contained' onClick={() => navigate('createnewstock')}>+ Create</Button>  
        <div className={styles.content}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Branch</th>
                <th>Stock ID</th>
              </tr>
            </thead>
            <tbody>
              {newStock.map((item, index) => {
                const {branch, date, id, itemList} = item;
                return (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td onClick={() => showDetail(id)}>{date}</td>
                    <td>{branch.toUpperCase()}</td>
                    <td>
                      {id}
                      {hideDetail === id ? (
                        <div style={{paddingBottom: 10}}>
                          
                          <div style={{ display: "none" }}>
                            <ComponentToPrint ref={componentRef} props={item} />
                          </div>
                          <div style={{paddingTop: 10, paddingBottom: 5}}>
                            <ReactToPrint
                              trigger={() => <button style={{width: '200px'}}>PRINT</button>}
                              content={() => componentRef.current}
                            />
                          </div>
                          {!item.isConfirmStock ? (
                            <div style={{paddingTop: 10, paddingBottom: 5}}>
                              <button style={{width: '200px'}} onClick={() => handleConfirmStock(item)}>CONFIRM STOCK</button>
                            </div>
                          ) : null}
                          

                          <p style={{paddingTop: 10, paddingBottom: 5}}>
                            <b>Item List</b>
                          </p>
                          {itemList.map(list => {
                          const {name, qty, id} = list;
                          return (
                            <p key={id}>{name} x {qty}</p>
                          )
                          })}
                        </div>
                      ) : null}
                      
                    </td>
                    
                  </tr>

                )
              })}
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default NewStockMng